@font-face {
  font-family: 'Mont';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('./Mont-Thin.woff') format('woff'),
  url('./Mont-Thin.woff2') format('woff2');
}

@font-face {
  font-family: 'Mont';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./Mont-RegularItalic.woff') format('woff'),
  url('./Mont-RegularItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Mont';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('./Mont-Regular.woff') format('woff'),
  url('./Mont-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Mont';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('./Mont-Black.woff') format('woff'),
  url('./Mont-Black.woff2') format('woff2');
}

@font-face {
  font-family: 'Lighthaus';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./Lighthaus.woff') format('woff'),
  url('./Lighthaus.woff2') format('woff2');
}