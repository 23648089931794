@import url(./utils/vendor/normalize.css);
@import url(./utils/vendor/fonts/fonts.css);


.app {
text-align: center;
}

.button-rainbow__button {
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  width: 326px;
  height: 50px;
  background: linear-gradient(to right, rgba(228, 161, 111, 0.8), rgba(11, 63, 55, 1));
  border: none;
  color: #FFFFFF;
  border-radius: 10px;
  font-size: 18px;
  line-height: 17.5px;
}

.button-rainbow__button:hover {
  opacity: 0.5;
}

.button-gray__button {

  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  width: 326px;
  height: 50px;
  background-color: white;
  border: 1px solid rgba(161, 161, 161, 1);
  color: rgba(161, 161, 161, 1);
  font-size: 18px;
  line-height: 17.5px;
  margin-top: 20px;
}

.button-gray__button:hover {
  opacity: 0.5;
}

.pagination > li > a {
  background-color: white;
  color: rgba(11, 63, 55, 1);
}

.page-item.active .page-link {
  color: #fff !important;
  background: rgba(11, 63, 55, 1);
  border: 1px solid rgba(11, 63, 55, 1);
}

.btn-primary {
  background-color: rgba(11, 63, 55, 1);
  color: white;
}

.header {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  background: linear-gradient(to right, rgba(228, 161, 111, 0.8), rgba(11, 63, 55, 1), rgba(11, 63, 55, 1), rgba(11, 63, 55, 1));
}

.basic__logo {
  margin-top: 50px;
}

.header__menu {
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 200;
  font-style: normal;
  display: flex;
  flex-direction: row;
  color: white;
  margin-top: 70px;
  margin-bottom: 60px;
}

.header__link {
  color: white;
  margin: 0 20px 0 20px;
  text-decoration: none;
  padding: 5px;
}

.header__count {
  text-decoration: none;
  color: rgba(228, 161, 111, 0.8);
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 21px;
  margin: 0;
}

.header__link:hover,.footer__link:hover {
  transform: translateX(10px) rotate(10deg) translateY(5px);
  color: white;
  background-color: rgba(228, 161, 111, 0.8);
  border-radius: 5px;
}


.header__group {
  display: flex;
  flex-direction: row;
  align-items: stretch;

}

.header__item {
  background-color: white;
  padding: 30px 10px 10px 10px;
  margin: 0 10px 10px 0;
  text-decoration: none;
}

.header__item:hover {
  transform: scale(1.1);
}

.footer {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  background: linear-gradient(to right, rgba(228, 161, 111, 0.8), rgba(11, 63, 55, 1), rgba(11, 63, 55, 1), rgba(11, 63, 55, 1));
  padding-top: 50px;
}

.footer__container {
  display: flex;
  flex-direction: column;
}

.footer__blocks {
  color: white;
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 200;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.footer__politics {

  text-decoration: underline;
  color: #FFFFFF;
}

.footer__block {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
  margin: 0 50px 74px 0;
}

.footer__text {
  font-weight: 800;
  font-size: 18px;
  line-height: 17.35px;
}

.footer__rights {
  font-weight: 400;
  color: #ADADAD;
  margin-top: 24px;
  margin-bottom: 21px;
  font-size: 14px;
  line-height: 19px;

}

.footer__text {
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 18px;
  line-height: 17px;

}

.footer__link {
  color: white;
  text-decoration: none;
  font-weight: 100;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 15px;
}

.footer__button {
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  border: none;
  outline: none;
  color: white;
  text-decoration: none;
  background-color: inherit;
  font-weight: 100;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 15px;
}


.footer__social {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer__item {
  margin: 0 16px 0 0;

}

.footer__contacts {
  margin: 0 0 15px 0;
  width: 157px;
  font-weight: 100;
  font-size: 14px;
  line-height: 19px;
}

.about {
  background-color: rgba(196, 196, 196, 0.2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 50px;

}

.about__menu {
  margin-top: 39px;
  margin-bottom: 30px;
  color: black;
  text-decoration: none;

}

.about__menu-links {
  color: black;
  text-decoration: none;
}

.about__title {
  margin-bottom: 50px;
}

.about__line {
  opacity: 0.3;
  margin-bottom: 50px;
}

.about__block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 60px;
}

.about__love {
  font-family: "Lighthaus", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  font-size: 50px;
  line-height: 48.2px;
  background-image: linear-gradient(90deg, rgba(94, 57, 40, 1), rgba(228, 161, 111, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 30px;
  width: 600px;
  text-align: start;

}

.about__name {
  font-family: "Lighthaus", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 600;
  font-size: 30px;
  line-height: 28.92px;
  background-image: linear-gradient(180deg, rgba(94, 57, 40, 1), rgba(228, 161, 111, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about__title {
  font-family: "Lighthaus", 'Helvetica Neue', Arial, sans-serif;
  font-size: 50px;
  font-weight: 400;
  line-height: 74.9px;

}

.about__logo {
  margin-top: 61px;
  margin-left: 100px;
}

.about__logo-image {
  width: 400px;
}

.about__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}

.about__box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.about__client-box {
  display: flex;
  flex-direction: column;
}

.about__us {
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 24.98px;
  width: 517px;
  margin-top: 23px;
}

.about__clients {
  font-family: "Lighthaus", 'Helvetica Neue', Arial, sans-serif;
  font-size: 50px;
  line-height: 52.95px;
  width: 517px;
}

.about__sale {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about__sale-number {
  margin-bottom: 150px;
}

.about__sale-name {
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 600;
  font-size: 50px;
  line-height: 48.2px;
  background-image: linear-gradient(180deg, rgba(94, 57, 40, 1), rgba(228, 161, 111, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 140px;
  margin-bottom: 25px;
}

.about__sale-number-image {
  width: 350px;
}

.history {
  background-color: black;
  margin: 0;
  padding: 30px 0 0 0;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

.history__container {
  display: flex;
  flex-direction: row;
  margin: 0 0 120px 50px;
}

.history__block {
  display: flex;
  flex-direction: column;
}

.history__title {
  font-family: "Lighthaus", 'Helvetica Neue', Arial, sans-serif;
  font-size: 155px;
  line-height: 116.1px;
  writing-mode: vertical-rl;
}

.history__text {
  font-family: "Lighthaus", 'Helvetica Neue', Arial, sans-serif;
  font-size: 50px;
  line-height: 52.95px;
  margin-top: 72px;
  margin-bottom: 28px;
}

.history__about {
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 100;
  font-size: 17px;
  line-height: 21.18px;
  width: 445px;
  margin-left: 50px;
  text-align: start
}

.catalog__box {
  margin-bottom: 50px;
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  grid-auto-rows: minmax(100px, auto);
}

.catalog {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.catalog__title {
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 600;
  font-size: 30px;
  line-height: 28.92px;
  background-image: linear-gradient(to right, rgba(11, 63, 55, 1), rgba(11, 63, 55, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  padding: 0;
  text-align: center;
}


.catalog__item {
  /* position: relative; */
  border: none;
  outline: none;
  background-color: #FFFFFF;
  padding: 0;
}

.catalog__text {
  /* position: absolute; */
  bottom: 60px;
  left: 70px;
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 19.28px;
  color: #FFFFFF;
}

.catalog__line {
  margin-bottom: 20px;
  border-top: 1px solid black;
  width: 100%;
  opacity: 0.5;
}

.catalog__flex {
  display: flex;
}

.catalog-likes__box {
  position: relative;
  margin-bottom: 50px;
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  grid-auto-rows: minmax(100px, auto);

}


.catalog-likes {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  justify-content: space-between;
  align-items: center;
}
.catalog__image{
  height:300px;
  width: 300px;
}

.itemBlock {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}

.itemBlock__cart {
  border: none;
  outline: none;
  background-color: transparent;
}

.itemBlock__img {
  max-height: 225px;
/*   min-height: 200px;
 */  height: 100%;
 max-width: 300px;
 min-width: 300px;
 width: 100%;
 object-fit: cover;
}

.itemBlock__delete {
  position: absolute;
  bottom: 72px;
  left: 0px;
  border: none;
  background: white;
  visibility: hidden;
}

.itemBlock__delete:hover {
  opacity: 0.5;
}

.itemBlock__like {
  position: absolute;
  bottom: 233px;
  left: 245px;
  border: none;
  background: none;
}

.itemBlock__like:hover {
  opacity: 0.8;
}

.itemBlock__box {
  display: flex;
  flex-direction: row;
}

.itemBlock__title {
  margin-right: 30px;
  max-width: 210px;
  max-height: 20px;
  overflow: hidden;
}

.itemBlock__button {
  border: none;
  outline: none;
  background: rgba(11, 63, 55, 1);
  margin-top: 10px;
  padding: 7px;
  color: #FFFFFF;
  border-radius: 5px;
  width: 200px;
}

.itemBlock__button_active {
  background: rgba(228, 161, 111, 1);
}

.itemBlock__button:hover {
  opacity: 0.5;
}

.cart {
  margin: 20px;
}

.cart__boxes {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  margin: 100px 0 100px 0;
}

.cart__menu {
  visibility: hidden;
}

.cart__menu_visible {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  visibility: visible
}

.cart__container {

  visibility: hidden
}

.cart__container_visible {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('./images/cart-full.svg') no-repeat center;
  visibility: visible;
  margin-left: 50px;
}

.cart__menu-item {
  margin: 0 50px 0 75px;
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.cart__delivery {
  margin-bottom: 50px;
  margin-left: 10px;
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.cart__delivery-container {
  display: flex;
  align-items: stretch;
  visibility: hidden;
  justify-content: center;
}

.cart__delivery-container_visible {
  visibility: visible;
}

.cart-full {
  display: flex;
  flex-direction: row;
  align-items: center;


}

.cart-item {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}


.cart-item__name {
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  width: 300px;
  margin: 0 0 0 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cart-item__total {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 100px;
  min-width: 70px;
}

.cart-item__box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cart-item__button {
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  border: 1px solid black;
  color: #FFFFFF;
  background-color: rgba(228, 161, 111, 1);
  width: 30px;
  height: 30px;
  border-radius: 5px;
}

.cart-item__button:hover {
  opacity: 0.5;
  scale: 1.2;

}

.cart-item__button-delete {
  background: #FFFFFF;
  border: none;
}

.cart-item__button-delete:hover {
  scale: 1.2;
}

.cart-item__buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cart-item__count {
  margin: 5px;
  min-width: 50px;
}

.container__box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cart-item__box-column {
  display: flex;
  flex-direction: row;

}

.cart-empty__image {
  margin: 50px 0 50px 0;
}

.itemBlock__group-heart {
  width: 50px;
  height: 56px;
  margin: 0 0 9px 11px;
  background-image: url(./images/likeemty.svg);
  border: hidden;
  cursor: pointer;
}

.itemBlock__group-heart:hover {
  opacity: 0.5;
}

.itemBlock__group-heart_active {
  background-image: url(./images/likefull.svg);

}

.saveLikesItems__margin {
  margin-top: 50px;
}

.profile {
  margin: 0 150px 150px 150px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start
}

.profile__title {
  margin-top: 30px;
  margin-bottom: 10px;
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  font-size: 50px;
  line-height: 52.95px;
}

.profile__box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 327px;
}

.profile__box-buttons {
  display: flex;
  flex-direction: column;
}

.profile__buttons-items {
  display: flex;
  margin-left: 30px;
}

.profile__text {
  margin-left: 10px;
}

.profile__link {
  display: flex;
  text-decoration: none;
  color: black;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: white;
  border: none;
}

.profile__box-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  width: 326px;
  height: 50px;
  background: linear-gradient(to right, rgba(228, 161, 111, 0.8), rgba(11, 63, 55, 1));
  border: none;
  margin: 0 50px 30px 0;
  color: #FFFFFF;
  border-radius: 10px;
  font-size: 18px;
  line-height: 17.5px;
  text-decoration: none;
}

.profile__box-button_active {

  background: linear-gradient(90deg, rgba(94, 57, 40, 1), rgba(228, 161, 111, 1));
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
}

.profile__box-button:hover {
  opacity: 0.5;

}

.profile__box-user {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.profile__box-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile__box-name {
  margin-left: 50px;
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-size: 20px;
  line-height: 19.28px;
}

.profile__box-text {
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-size: 20px;
  line-height: 19.28px;
  width: 250px;
}

.profile__contain {
  display: flex;
  flex-direction: row;
}

.profile__contain-box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 5px;
  align-items: flex-start;

}

.profile__input {

  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  width: 396px;
  height: 46px;
  border: 1px solid #555;
  margin: 5px;
}

.profile-title__margin {
  margin: 50px 0 50px 0;
}

.login {
  display: flex;
  justify-content: center;
}

.login__form {
  margin: 70px 36px 0 36px;
  padding: 0;
  width: 395px;
}

.login__title {
  margin-top: 90px;
  font-family: "Lighthaus", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  font-size: 50px;
  line-height: 52.95px;
  color: rgba(11, 63, 55, 1);
}

.login__input {
  border: 1px solid #555;
  font-family: "Inter", 'Helvetica Neue', Arial, sans-serif;
  font-size: 14px;
  line-height: 16.94px;
  font-weight: 400;
  width: 396px;
  height: 46px;

}

.login__input-text {
  color: #8B8B8B;
  display: flex;
  justify-content: left;
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-size: 18px;
  margin: 20px 0 10px 0;
}

.login__link {
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  margin-bottom: 70px;
  margin-top: 30px;
  color: rgba(11, 63, 55, 1);;
  text-decoration: none;
  display: flex;
  justify-content: center;
}

.login__link:hover {
  opacity: 0.5;
  transition: all 1s ease-out;
}

.login__button {
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  width: 326px;
  height: 50px;
  background: linear-gradient(to right, rgba(228, 161, 111, 0.8), rgba(11, 63, 55, 1));
  border: none;
  margin: 30px 0 0 0;
  color: #FFFFFF;
  border-radius: 10px;
  font-size: 18px;
  line-height: 17.5px;
}

.login__button:disabled {
  opacity: 0.2;
}

.login__button-margin {
  margin-top: 30px;
  margin-right: 10px;
}

.login__valid {
  color: red;
}

.small-header {
  background: linear-gradient(to right, rgba(228, 161, 111, 0.8), rgba(11, 63, 55, 1), rgba(11, 63, 55, 1), rgba(11, 63, 55, 1));
  padding-top: 30px;
  padding-bottom: 30px;
}

.small-footer {
  background: linear-gradient(to right, rgba(228, 161, 111, 0.8), rgba(11, 63, 55, 1), rgba(11, 63, 55, 1), rgba(11, 63, 55, 1));
  padding-top: 30px;
  padding-bottom: 30px;
}

.small-footer__text {
  color: #FFFFFF;
  font-family: "Lighthaus", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-top: 10px;
}

.thanks__text {
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 24.98px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.thanks__logo {
  margin-top: 30px;
  margin-bottom: 30px;
}

.delivery {
  margin: 50px 0 50px 0;
}

.delivery-block {
  display: flex;
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0 0 50px 0;
  border: 5px solid rgba(11, 63, 55, 0.5);
  border-radius: 10px;
  padding: 20px;
}

.delivery-block__date {
  font-size: 20px;
  line-height: 24.98px;
  margin-top: 30px;
  font-weight: 500;
}

.delivery-block__title {
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 800;
  font-size: 30px;
  text-align: start;
}

.delivery-block__text {
  font-size: 20px;
  line-height: 24.98px;
  margin-top: 30px;
  font-weight: 500;
  text-align: start;
}

.checkout {
  margin-left: 100px;
  display: flex;
}

.checkout__box-form {
  margin-bottom: 50px;
}

.checkout__button-title {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 50px;
}

.checkout__button-form {
  margin-right: 50px;
}

.checkout__box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.checkout__ratio-box {
  display: flex;
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
}

.checkout__title-legend {
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
}

.checkout__title {
  font-family: "Lighthaus", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  font-size: 50px;
  line-height: 52.95px;
  color: rgba(11, 63, 55, 1);
}

.checkout__input {
  height: 50px;
  width: 327px;
  border: 1px solid rgba(161, 161, 161, 1);
  color: rgba(161, 161, 161, 1);
}

.checkout__input:focus {
  outline: none;
}

.checkout__data {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  justify-items: center;
  grid-gap: 30px;
}

.checkout__data-title {
  margin-top: 50px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.checkout__textarea {
  width: 400px;
  height: 200px;
  outline: none;
  border: 1px solid rgba(161, 161, 161, 1);
  color: rgba(161, 161, 161, 1);
}

.number-box {
  background: linear-gradient(to right, rgba(228, 161, 111, 0.8), rgba(11, 63, 55, 1), rgba(11, 63, 55, 1), rgba(11, 63, 55, 1));
  width: 36px;
  height: 36px;
  margin-right: 10px;

}

.number-box__name {
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 800;
  color: #FFFFFF;
  font-size: 30px;
  line-height: 28.92px;
  text-align: center;
}

.cart-mini {
  width: 450px;
  margin-top: 120px;
  text-align: center;

}

.cart-mini__box-items {
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: center;
}

.cart-mini__box-items::-webkit-scrollbar {
  width: 15px;
  background-color: rgba(11, 63, 55, 0.5);
  border-radius: 5px;
}

.cart-mini__box-items::-webkit-scrollbar-thumb {
  background-color: rgba(11, 63, 55, 1);
  border-radius: 5px;
}

.cart-item-mini {
  width: 425px;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;

}

.cart-item-mini__image {
  margin: 20px;

}

.cart-item-mini__box {
  display: flex;
  justify-content: space-around;
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
}

.cart-item-mini__name-box {
  display: flex;
  flex-direction: column;
}

.cart-item-mini__name {
  margin-bottom: 10px;
}

.cart-total-mini {
  width: 425px;
  height: 430px;
}

.cart-total-mini__box-delivery {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.register-mini {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 920px;
  height: 125px;
  background-color: #E6E8E7;
  background-image: linear-gradient(rgba(11, 63, 55, 0.1), rgba(11, 63, 55, 0.3), rgba(11, 63, 55, 0.5), rgba(11, 63, 55, 0.8));
  border-radius: 25px;

}

.profile-mini {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 920px;
  height: 125px;
  background-color: #E6E8E7;
  background-image: linear-gradient(rgba(11, 63, 55, 0.1), rgba(11, 63, 55, 0.3), rgba(11, 63, 55, 0.5), rgba(11, 63, 55, 0.8));
  border-radius: 25px;
}

.register-mini__box {
  display: flex;
  flex-direction: column;
  color: #FFFFFF;
}

.register-mini__button {
  width: 187px;
  height: 50px;
  margin: 0;
}

.register-mini__title {
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  font-size: 20px;
  width: 300px;
}

.register-mini__text {
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  width: 300px;
}

.orders {
  width: 900px;
}

.orders__menu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.orders__menu-items {
  display: flex;
  flex-direction: row;
}

.orders__menu-item {
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin-left: 90px;
}

.orderItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.orderItem__box-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.orderItem__box-name {
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
}

.orderItem__box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.orderItem__text {
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin-left: 90px;
}

.ordersEmpty {
  display: flex;
  flex-direction: column;
}

.ordersEmpty__title {
  font-family: "Lighthaus", 'Helvetica Neue', Arial, sans-serif;
}

.like-items-empty {
  margin-bottom: 50px;
}

.like-items-empty__text {
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 30px;
}

.popup {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: rgba(11, 63, 55, 1);
}

.popup__title {
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  color: #FFFFFF;
  font-size: 20px;
}

.popup__text {
  font-family: "Mont", 'Helvetica Neue', Arial, sans-serif;
  font-weight: 100;
  color: #FFFFFF;
  font-size: 15px;
  text-align: center;
}

.popup__close {
  background: url('../../user-browser/src/images/Close.svg') no-repeat center ;
  position: absolute;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  top: 0;
  right: 0;
}

.popup__close:hover {
  opacity: 60%;
}
.not-found{
  margin: 100px 0 100px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.not-found__title{
  margin: 10px 0 10px 0;
}
.popup-item{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.5);
  transition: .5s;
  z-index: 200;
}
.popup-item__container{
  width: 660px;
  border-radius: 20px;
  background-color: #fff;
  position: relative;
  z-index: 2;
}

.popup-item_opened {
  display: flex;
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
  transition: visibility 0s, opacity 0.6s;
}
.popup-item__close {
  background: transparent url('../../user-browser/src/images/Close.svg');
  width: 32px;
  height: 32px;
  position: absolute;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  top: -30px;
  right: -31px;
}
@media screen and (max-width: 920px) {
  .catalog__box {
    display: flex;
    flex-direction: column;
  }

  .header {
    justify-content: center;
  }

  .header__menu {
    flex-direction: column;
  }

  .footer {
    align-items: center;
    flex-direction: column-reverse;
  }

  .footer__container {
    margin-bottom: 50px;
  }

  .footer__block {
    margin: 0;
  }

  .about__logo-image {
    width: 200px;
  }

  .about__love {
    font-size: 15px;
    line-height: 14.2px;
    max-width: 300px;
  }

  .about__title {
    font-size: 30px;
    line-height: 28.2px;
  }

  .about__name {
    margin-top: 10px;
    font-size: 15px;
    line-height: 13.92px;
  }

  .about__clients {
    font-size: 30px;
    line-height: 32.95px;
    max-width: 300px;
  }

  .about__us {
    font-size: 15px;
    max-width: 300px;
  }

  .history__container {
    flex-direction: column;
    margin: 0 0 20px 0;
  }

  .history__text {
    max-width: 300px;
  }

  .history__about {
    max-width: 300px;
    text-align: center;
    margin: 50px 0 0 0;
  }


  .profile__input {
    width: 200px;
  }

  .profile__buttons-items {
    display: flex;
    max-width: 300px;
  }

  .profile__box-text {
    max-width: 100px;
  }

  .catalog__title {
    max-width: 300px;
  }

  .like-items-empty__text {
    max-width: 300px;
  }

  .login__input {
    max-width: 326px;
  }

  .login__form {
    max-width: 326px;
  }

  .login__title {
    font-size: 30px;
    line-height: 32.95px;
  }

  .cart-item {
    justify-content: center;
  }

  .cart__boxes {
    margin: 0;
    justify-content: center;
  }

  .cart-item__product {
    max-width: 250px;
  }

  .cart-item__price {
    max-width: 100px;
  }

  .cart__menu-item {
    margin: 0 30px 0 0;
  }

  .cart__container_visible {
    margin-left: 0;
  }

  .cart-item__total {
    margin-left: 5px;
    min-width: 50px;
  }

  .cart-item__name {
    margin: 0;
    width: 150px;
  }

  .profile-mini {
    width: 300px;
  }

  .register-mini__title, .register-mini__text {
    width: 200px;
  }

  .checkout__title {
    font-size: 30px;
    line-height: 32.95px;
  }

  .checkout__button-title {
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .checkout__data {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .checkout__box {
    align-items: center;
  }

  .checkout__textarea {
    width: 300px;

  }

  .cart-mini {
    margin-top: 20px;
  }

  .cart-total-mini {
    width: 425px;
    max-height: 230px;
    margin-top: 20px;
  }

  .cart-item-mini {
    justify-content: center;
  }
  .catalog-likes__box {
    display: flex;
    flex-direction: column;
  }
}


@media screen and (max-width: 1280px) {
  .catalog__box {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }

  .about {
    flex-direction: column;
    align-items: center;

  }

  .about__block {
    margin-left: 0;
  }

  .about__love {
    font-size: 35px;
    line-height: 34.2px;
    text-align: center;

  }

  .about__title {
    font-size: 50px;
    line-height: 48.2px;
  }

  .about__logo-image {
    width: 400px;
  }

  .about__name {
    margin-top: 20px;
    font-size: 25px;
    line-height: 23.92px;
  }

  .about__block {
    align-items: center;
  }

  .about__box {
    margin-top: 20px;
    flex-direction: column;
  }

  .about__line {
    display: none
  }

  .about__client-box {
    align-items: center;
  }

  .about__sale-number-image {
    width: 150px;
  }

  .history__text {
    max-width: 900px;
  }

  .history__title {
    display: none;
  }

  .history__about {
    margin-left: 10px;
    max-width: 400px;
  }

  .history__block {
    align-items: center;
  }

  .about__logo {
    margin-left: 0;
  }
  .catalog-likes__box {
    grid-template-columns: repeat(2, 1fr);

  }


  .profile__buttons-items {
    margin-left: 0;
    justify-content: center;
  }

  .profile {
    margin: 0;
    flex-direction: column;
    align-items: center;
  }

  .profile__box-button {
    margin: 0 0 30px 0;
  }

  .cart-full {
    flex-direction: column;
  }

  .checkout {
    margin-left: 0;
    flex-direction: column;
    align-items: center;
  }

  .checkout__box-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
